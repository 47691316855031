import React, { useState } from 'react';
import { PageProps } from 'gatsby';
import Header from '../../components/header/header';
import { TranslationService } from '../../services/translation.service';
import * as styles from './company-signup.module.scss';
import { TranslationProvider } from '../../contexts/translation.context';
import { useForm } from '@/src/hooks/form.hook';
import { createFormField, emailValidator, FormConfig, passwordMatchValidator, requiredValidator, yearValidator } from '@/src/models/form.model';
import { FormError } from '@/src/components/form-error/form-error';
import { LargeButton } from '@/src/components/button/button';
import Footer from '@/src/components/footer/footer';
import Spinner from '@/src/components/spinner/spinner';
import { Helmet } from 'react-helmet';
import firebase from 'gatsby-plugin-firebase';

const year = (new Date()).getFullYear();
const years = Array.from(new Array(100), (val, index) => -(index - year));

const companySignupForm: FormConfig = {
  companyName: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="companyName"
            name="companyName"
            placeholder={translation.translate('company_signup.company_name')}
            type="text"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      },
    ),
    validationRules: [
      requiredValidator()
    ]
  },
  address: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="address"
            name="address"
            placeholder={translation.translate('authentication.address')}
            type="text"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator()
    ]
  },
  zip: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="zip"
            name="zip"
            placeholder={translation.translate('authentication.zip')}
            type="text"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator()
    ]
  },
  city: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="city"
            name="city"
            placeholder={translation.translate('authentication.city')}
            type="text"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator()
    ]
  },
  firstName: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="firstName"
            name="firstName"
            placeholder={translation.translate('authentication.first_name')}
            type="text"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator()
    ]
  },
  lastName: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="lastName"
            name="lastName"
            placeholder={translation.translate('authentication.last_name')}
            type="text"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      },
    ),
    validationRules: [
      requiredValidator()
    ]
  },
  email: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="email"
            name="email"
            placeholder={translation.translate('authentication.email')}
            type="email"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator(),
      emailValidator()
    ]
  },
  phone: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="phone"
            name="phone"
            placeholder={translation.translate('company_signup.phone')}
            type="text"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      },
    ),
    validationRules: [
      requiredValidator()
    ]
  },
};

export default function SignupPage({ pageContext }: PageProps) {
  const [translation] = useState(new TranslationService(pageContext));
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(false);
  const [applicationSent, setapplicationSent] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const { renderInput, isFormValid, touchForm, form } = useForm(translation, companySignupForm);

  const signup = async (event: React.FormEvent) => {
    event.preventDefault();
    touchForm();

    if(!isFormValid()) {
      return;
    }
    setFormError(false);
    setLoading(true);

    const formData = {
      // Company
      companyName: form.companyName.value,
      address: {
        street: form.address.value,
        city: form.city.value,
        zip: form.zip.value
      },

      // Company owner
      firstName: form.firstName.value,
      lastName: form.lastName.value,
      email: form.email.value,
      phone: form.phone.value,
    };

    let result;
    await firebase.app().functions('europe-west3').httpsCallable('companyOwnerApplication')(formData).then((res) => {
      result = { success: true, message: '' };
    }).catch((error) => {
      result = { success: false, message: error.message };
    });

    console.log(result);

    setLoading(false);

    if(!result.success) {
      setFormError(true);
      switch(result.message) {
        case 'email-already-exists':
          setFormErrorMessage(translation.translate('authentication.email_already_exists'));
          break;
        case 'invalid-argument':
          setFormErrorMessage(translation.translate('authentication.invalid_argument'));
          break;
        default:
          setFormErrorMessage(translation.translate('authentication.unexpected_error'));
          break;
      }
    } else {
      setapplicationSent(true);
    }
  };

  return (
    <TranslationProvider value={translation}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{translation.translate('helmet.signup_title')}</title>
        <meta name="description" content={translation.translate('helmet.signup_description')} />
        <meta http-equiv="content-language" content={translation.lang} />
      </Helmet>
      <Header></Header>

      <div className="scrollable no-navbar">
        {applicationSent ?
          <div className="content">
            <div className="app__wrapper">
              <p className={styles.applicationSent}>
                {translation.translate('company_signup.application_sent')}
              </p>
            </div>
          </div>
          :
          <div className="content">

            <h1 className={styles.heading}>
              {translation.translate('company_signup.create_company_account')}
            </h1>
            <p className={styles.headingDescription}>
              {translation.translate('company_signup.description')}
            </p>

            <div className="app__wrapper">
              <form className="app__form" onSubmit={signup}>

                <h2 className={styles.subHeading}>
                  {translation.translate('company_signup.company_credentials')}
                </h2>
                <div className="form__group">
                  <div className="form__field">
                    <label htmlFor="companyName">{translation.translate('company_signup.company_name_label')}<span className="required-label">*</span></label>
                    {renderInput('companyName')}
                  </div>
                </div>

                <div className="form__group">
                  <div className="form__field">
                    <label htmlFor="address">{translation.translate('authentication.address')}<span className="required-label">*</span></label>
                    {renderInput('address')}
                  </div>
                </div>

                <div className="form__group">
                  <div className="form__field">
                    <label htmlFor="zip">{translation.translate('authentication.zip')}<span className="required-label">*</span></label>
                    {renderInput('zip')}
                  </div>
                  <div className="form__field">
                    <label htmlFor="city">{translation.translate('authentication.city')}<span className="required-label">*</span></label>
                    {renderInput('city')}
                  </div>
                </div>

                <h2 className={styles.subHeading}>
                  {translation.translate('company_signup.company_owner_credentials')}
                </h2>

                <div className="form__group">
                  <div className="form__field">
                    <label htmlFor="firstName">{translation.translate('authentication.first_name')}<span className="required-label">*</span></label>
                    {renderInput('firstName')}
                  </div>

                  <div className="form__field">
                    <label htmlFor="lastName">{translation.translate('authentication.last_name')}<span className="required-label">*</span></label>
                    {renderInput('lastName')}
                  </div>
                </div>

                <div className="form__group">
                  <div className="form__field">
                    <label htmlFor="email">{translation.translate('authentication.email')}<span className="required-label">*</span></label>
                    {renderInput('email')}
                  </div>
                  <div className="form__field">
                    <label htmlFor="phone">{translation.translate('company_signup.phone')}<span className="required-label">*</span></label>
                    {renderInput('phone')}
                  </div>
                </div>


                {formError ?
                  <div className={styles.errorMessage}>
                    {formErrorMessage}
                  </div> : null}

                <div className="form__group form__group--submit">
                  <LargeButton type="submit" disabled={loading}>
                    {loading ? <Spinner></Spinner> : null}
                    <span>{translation.translate('company_signup.apply')}</span>
                  </LargeButton>
                </div>

              </form>
            </div>
          </div>
        }

        <Footer></Footer>
      </div>
    </TranslationProvider>
  );
}
